export const YouTubeLinks = {
"cpb-aacip-127-00ns1t6z.h264":"https://youtu.be/hMJ0gB0ylPI",
"cpb-aacip-127-03cz8zdq.h264":"https://youtu.be/-oqXpqmz64A",
"cpb-aacip-127-06g1jzz6.h264":"https://youtu.be/NQMiCjkAz5k",
"cpb-aacip-127-06g1k008.h264":"https://youtu.be/D8M7-uVd53U",
"cpb-aacip-127-09w0vx3c.h264":"https://youtu.be/oKI5S54Gwks",
"cpb-aacip-127-009w0z0q.h264":"https://youtu.be/jLADGxg1i1k",
"cpb-aacip-127-10jsxpvr.h264":"https://youtu.be/oyzya9ND7HA",
"cpb-aacip-127-10jsxpwg.h264":"https://youtu.be/fQI71UcW4r0",
"cpb-aacip-127-10jsxpx6.h264":"https://youtu.be/MYCs6fQ8J0U",
"cpb-aacip-127-010p2r15.h264":"https://youtu.be/Ask0wqPPCJE",
"cpb-aacip-127-14nk9d19.h264":"https://youtu.be/CUzt83vgGO0",
"cpb-aacip-127-15p8d31m.h264":"https://youtu.be/LaoAIQC29aQ",
"cpb-aacip-127-16pzgr3f.h264":"https://youtu.be/9qV4Il1_Ymg",
"cpb-aacip-127-18rbp380.h264":"https://youtu.be/lpcINJcdQd0",
"cpb-aacip-127-20fttjr7.h264":"https://youtu.be/VP5MRm_DjLw",
"cpb-aacip-127-25k98x78.h264":"https://youtu.be/1c3iURyqRXY",
"cpb-aacip-127-25x69tk3.h264":"https://youtu.be/eFwoxqaHUTw",
"cpb-aacip-127-26xwdh7k.h264":"https://youtu.be/4POSJpQc4Sw",
"cpb-aacip-127-27zkh5wz.h264":"https://youtu.be/mjirfUQt8t4",
"cpb-aacip-127-28ncjzpp.h264":"https://youtu.be/Fn4A2uYZOGs",
"cpb-aacip-127-33rv1b2m.h264":"https://youtu.be/NXDJPMMkka0",
"cpb-aacip-127-34fn33pp.h264":"https://youtu.be/tyPs3QlX9s4",
"cpb-aacip-127-34sj40gx.h264":"https://youtu.be/h_KQABw6R0k",
"cpb-aacip-127-35gb5sdj.h264":"https://youtu.be/rXpvSabk-PE",
"cpb-aacip-127-35t76q2k.h264":"https://youtu.be/I3Hi1TOwiQE",
"cpb-aacip-127-37hqc4tk.h264":"https://youtu.be/mB8t35Kgjp8",
"cpb-aacip-127-37vmd1rq.h264":"https://youtu.be/H2hTW3BZNsw",
"cpb-aacip-127-38w9gpk2.h264":"https://youtu.be/m8zENpVs0Qk",
"cpb-aacip-127-41mgqv1k.h264":"https://youtu.be/CJe8aKZ__I0",
"cpb-aacip-127-42n5thvk.h264":"https://youtu.be/FhygcQ39sWk",
"cpb-aacip-127-47rn8x0g.h264":"https://youtu.be/YVlOLVmxL24",
"cpb-aacip-127-48ffbpfx.h264":"https://youtu.be/Rii_x67pTUM",
"cpb-aacip-127-52j6qcr2.h264":"https://youtu.be/6Mh2yj2mRr4",
"cpb-aacip-127-53wstz0s.h264":"https://youtu.be/gKQ5ktMo5xI",
"cpb-aacip-127-54kkwqvx.h264":"https://youtu.be/XYxjGozuYAQ",
"cpb-aacip-127-54kkwrd6.h264":"https://youtu.be/Hd7jmcS--Nw",
"cpb-aacip-127-57np5s39.h264":"https://youtu.be/fwc8hqoJN-o",
"cpb-aacip-127-59c5b6f6.h264":"https://youtu.be/-kpGFp3qdXE",
"cpb-aacip-127-59c5b6gx.h264":"https://youtu.be/xvTwjd0xXRE",
"cpb-aacip-127-59q2c3zn.h264":"https://youtu.be/9BvTq72t_Rc",
"cpb-aacip-127-60qrfsqn.h264":"https://youtu.be/oyZkHPOhwpI",
"cpb-aacip-127-63fxpxg0.h264":"https://youtu.be/X_XDJ_A-x6s",
"cpb-aacip-127-65h9w88k.h264":"https://youtu.be/2FVH46k9Fyk",
"cpb-aacip-127-65v6x4wh.h264":"https://youtu.be/oZIlRwddGoc",
"cpb-aacip-127-66vx0v02.h264":"https://youtu.be/cpRGd90X5iA",
"cpb-aacip-127-67wm3hhj.h264":"https://youtu.be/lbci2wmoK1o",
"cpb-aacip-127-68kd59ft.h264":"https://youtu.be/KMd38gH_WZ0",
"cpb-aacip-127-69m37zr9.h264":"https://youtu.be/FcxAzJnluPw",
"cpb-aacip-127-74cnpfqb.h264":"https://youtu.be/ugJSbkpSgQ4",
"cpb-aacip-127-74cnpfr2.h264":"https://youtu.be/W67yhD0z_o4",
"cpb-aacip-127-74qjqbsc.h264":"https://youtu.be/nUxTxdHoUzQ",
"cpb-aacip-127-76f1vspw.h264":"https://youtu.be/_8Y3eOdsMIk",
"cpb-aacip-127-79h44thw.h264":"https://youtu.be/ZJfUif3iGRQ",
"cpb-aacip-127-81jhb5w6.h264":"https://youtu.be/tGz9BCidw-c",
"cpb-aacip-127-84mkm6tr.h264":"https://youtu.be/3YcwGvSU7Sw",
"cpb-aacip-127-085hqfqk.h264":"https://youtu.be/6b3d9F8Fymg",
"cpb-aacip-127-85n8pw5n.h264":"https://youtu.be/jHshIYYaaTc",
"cpb-aacip-127-86nzsk0r.h264":"https://youtu.be/fNhbFccM3NQ",
"cpb-aacip-127-87brvbt5.h264":"https://youtu.be/pJtl11O0V9g",
"cpb-aacip-127-87pnw7r9.h264":"https://youtu.be/qdrzjbTg8aE",
"cpb-aacip-127-89r22kjv.h264":"https://youtu.be/afLIDmc9rLA",
"cpb-aacip-127-90rr586b.h264":"https://youtu.be/BGebtY8OKro",
"cpb-aacip-127-91sf7xm0.h264":"https://youtu.be/s8pQGOgZB4w",
"cpb-aacip-127-96wwq9xv.h264":"https://youtu.be/080ZZ18Fpd0",
"cpb-aacip-127-98mcvs0p.h264":"https://youtu.be/WECzXd33X_U",
"cpb-aacip-127-149p8hcz.h264":"https://youtu.be/c09r3zdeWoQ",
"cpb-aacip-127-225b014v.h264":"https://youtu.be/INJlSx5vQiI",
"cpb-aacip-127-257d81kk.h264":"https://youtu.be/d0S-u1TRNQI",
"cpb-aacip-127-257d81m9.h264":"https://youtu.be/bhSq4DnYBNc",
"cpb-aacip-127-322bvwfr.h264":"https://youtu.be/T9gm_m1qJqY",
"cpb-aacip-127-386hdxkk.h264":"https://youtu.be/q3fmjBhBAGk",
"cpb-aacip-127-386hdz1p.h264":"https://youtu.be/JxxsJc0DLrc",
"cpb-aacip-127-440rz436.h264":"https://youtu.be/Vl6KIq9WaHo",
"cpb-aacip-127-558czhn9.h264":"https://youtu.be/_6LFLZhXwLk",
"cpb-aacip-127-612ngp56.h264":"https://youtu.be/lngL8_jvjBM",
"cpb-aacip-127-623bkbxr.h264":"https://youtu.be/e0-kFON_PvY",
"cpb-aacip-127-0644j37r.h264":"https://youtu.be/-lM97sptJYU",
"cpb-aacip-127-0644j324.h264":"https://youtu.be/9u1ZlD5lQl4",
"cpb-aacip-127-719kdf8m.h264":"https://youtu.be/xIHoTMdkMaM",
"cpb-aacip-127-719kdfjd.h264":"https://youtu.be/l0dKlPh2I50",
"cpb-aacip-127-752fr7q2.h264":"https://youtu.be/lVZ3PGnAtGs",
"cpb-aacip-127-773txm5v.h264":"https://youtu.be/VK0JFk2NGp0",
"cpb-aacip-127-784j17vw.h264":"https://youtu.be/pl7d1_fUs1s",
"cpb-aacip-127-805x6mnx.h264":"https://youtu.be/aemMp-XQ83Q",
"cpb-aacip-127-816m99hb.h264":"https://youtu.be/LqIUxpzcRpM",
"cpb-aacip-127-881jx4d6.h264":"https://youtu.be/b4ymvPJ0UBg",
"cpb-aacip-127-945qg6jq.h264":"https://youtu.be/8LKyI_KvduY",
"cpb-aacip-127-956djvkd.h264":"https://youtu.be/bC_VoIz_HW8",
"cpb-aacip-127-988gtvzp.h264":"https://youtu.be/OG6n3Lzhkjo",
"cpb-aacip-127-2908kvc9.h264":"https://youtu.be/tagUSZZdUDs",
"cpb-aacip-127-6341p189.h264":"https://youtu.be/WfaHqnOZTrY",
"cpb-aacip-127-7634tx89.h264":"https://youtu.be/faIDfTeraMI",
"cpb-aacip-127-23612qck.h264":"https://youtu.be/30wNZ3n0P0s",
"cpb-aacip-127-032282mq.h264":"https://youtu.be/vsFIh3Rnets",
"cpb-aacip-127-46254fdx.h264":"https://youtu.be/3uZB40vpTYs",
"cpb-aacip-127-89280sv6.h264":"https://youtu.be/oMxVjRSc06k",
};

