export const categoriesUrlLookup = {
    "Yugtun-Qalarcaraq-Qaneryaraq": "1",
    "Qalarcarat": "1.1",
    "Yugtun-Yugcetun": "1.1.1",
    "Cugtun-Yugtun": "1.1.2",
    "Uyaquq": "1.2",
    "Uyaqum-Igaryarai": "1.2.1",
    "Nunat-Meret-llu-Atrit": "2",
    "Meret-Atrit": "2.1",
    "Kusquqvak": "2.1.2",
    "Kiillaralliin": "2.1.3",
    "Nunacuaraat-Atrit": "2.2",
    "Tapraq": "2.2.3",
    "Nunam-Iqua": "2.2.8",
    "Asaacarsaq": "2.2.9",
    "Negeqliq": "2.2.10",
    "Negeqliim-Painga": "2.2.11",
    "Naparyaarmiut": "2.2.17",
    "Cevaq": "2.2.18",
    "Kayalivik": "2.2.20",
    "Tununeq": "2.2.21",
    "Nunakauyaq": "2.2.22",
    "Negteḿiut": "2.2.23",
    "Qipneq": "2.2.26",
    "Kuigilnguq": "2.2.27",
    "Kangirnaq": "2.2.28",
    "Ekvicuar": "2.2.29",
    "Tuntutuliaq": "2.2.30",
    "Qinaq": "2.2.31",
    "Naparyarraq": "2.2.32",
    "Napaskiaq": "2.2.33",
    "Kuiggayagaq": "2.2.34",
    "Nunapicuaq": "2.2.35",
    "Kassigluq": "2.2.36",
    "Atmaulluaq": "2.2.37",
    "Mamterilleq": "2.2.38",
    "Kuiggluk": "2.2.39",
    "Akiacuar": "2.2.40",
    "Akiaq": "2.2.41",
    "Tuulkessaaq": "2.2.42",
    "Kuinerraq": "2.2.48",
    "Nanvarnarrlak": "2.2.64",
    "Urucaraq": "2.2.65",
    "Nunacuaq": "2.2.66",
    "Acacaryun": "2.2.67",
    "Anchorage": "2.2.68",
    "Noatak": "2.2.69",
    "Kotzebue": "2.2.70",
    "Ingriq": "2.3",
    "Ellavut": "3",
    "Allrakum-Ellalinqigutai": "3.1",
    "Upnerkaq-Upenerkaq": "3.1.1",
    "Uksuaq": "3.1.2",
    "Kiak": "3.1.3",
    "Ellaliuryaraq": "3.2",
    "Ellassuun": "3.2.1",
    "Ciku": "3.4",
    "Kianeq": "3.4.1",
    "Qanikcaq": "3.5",
    "Urugyaraa": "3.5.1",
    "Iraluq": "3.6",
    "Agyat": "3.8",
    "Nunavut": "3.9",
    "Uqlaneq": "3.9.1",
    "Neqet-Cumacitartut": "3.9.1.1",
    "Nunam-Auluksaraa": "3.9.2",
    "Menuunani": "3.9.2.1",
    "Nunam-Ataniuryaraa": "3.9.3",
    "Ulerpak": "3.10",
    "Ellarrluk": "3.11",
    "Qilagkun-Sassaq": "3.12",
    "Akerta": "3.12.1",
    "Yuut": "4",
    "Ayagyuat": "4.1",
    "Tegganeq": "4.2",
    "Nukalpiaq": "4.3",
    "Nengauk": "4.4",
    "Elliraat": "4.5",
    "Ungungssit-Mermiutaat-llu": "5",
    "Neqa-Neqet": "5.1",
    "Cangiiq-Imangaq": "5.1.1",
    "Alungyaraat": "5.1.1.1",
    "Meluk-Imlauk": "5.1.3",
    "Yaqulek-Yaqulget-Tengmiaq-Tengmiat": "5.2",
    "Qangqiiq": "5.2.1",
    "Ingtaq-Ingtat": "5.2.2",
    "Ungungssit-Yuilqurmiutaat-Nunamiutaat": "5.3",
    "Tuntu": "5.3.1",
    "Imarmiutaq": "5.3.2",
    "Kegluneq": "5.3.3",
    "Cuignilnguq": "5.3.4",
    "Tangerliq": "5.3.6",
    "Taqukaq-Carayaq": "5.3.7",
    "Qanganaq": "5.3.8",
    "Tuntuvak": "5.3.9",
    "Tuntupik-Tuntupiaq": "5.3.10",
    "Imarpigmiutaat-Unkumiutaat-Mermiutaat": "5.4",
    "Maklak": "5.4.1",
    "Nayiq": "5.4.2",
    "Puga": "5.4.3",
    "Cetuaq": "5.4.4",
    "Piyagaq": "5.5",
    "Naunraat-Atsat-llu": "6",
    "Atsat": "6.1",
    "Naunraat": "6.2",
    "Quagciq": "6.2.2",
    "Uqviit-Enrilnguat": "6.2.3",
    "Canek-Canget-Caranglluk-Evek": "6.3",
    "Iitaq": "6.3.4",
    "Kelugkaq": "6.3.5",
    "Issran": "6.3.6",
    "Kalngak": "6.3.7",
    "Naqtat": "6.3.8",
    "Naucetaat": "6.4",
    "Naucetaanek-Enuqitellriit": "6.4.1",
    "Akluq-Aklut": "7",
    "Kassartaat": "22.12.1",
    "Amiq-Qecik": "7.4",
    "Ivrucit": "7.4.1",
    "Amirak-Amiraq": "7.4.2",
    "Neqairiyaraq": "7.4.2.1",
    "Allat": "24",
    "Imarnin": "7.5.1",
    "Ukverput-Agayuliyaraq": "8",
    "Agayumayaraq-Agayumaciq": "8.1",
    "Agayuviit": "8.1.1",
    "Moravian": "8.1.1.1",
    "Kassalugpiaq": "8.1.1.2",
    "Agayulirtet": "8.1.2",
    "Qaneryaraq-Qaneryaraqegtaar": "8.1.3",
    "Anglluryaraq": "8.1.4",
    "Piicak": "8.1.5",
    "Ellam-Yua-Cellam-Yua-Cillam-Cua": "8.2",
    "Piunrillret-Yuunrillret-Tuqullret-Catairutellret": "8.3",
    "Angalkut-Angalkuut": "8.4",
    "Unatellget": "8.4.2",
    "Tuunriyaraq-Angalkiyaraq": "8.4.3",
    "Umyuarrliqellriit": "8.4.4",
    "Aniqlaarutet": "8.4.4.1",
    "Alangru-Aliurtuq": "8.5",
    "Qimugtet-Elpegtauciatnek": "8.5.1",
    "Aviukaryaraq": "8.6",
    "Nerangnaqsaraq-Yuungnaqsaraq": "9",
    "Pissuryaraq": "9.1",
    "Qamigaryaraq": "9.1.1",
    "Uurcaq": "9.1.1.1",
    "Yaqulecuarteggun-Elitnauryaraq": "9.1.2",
    "Pingnatugyaraq": "9.2",
    "Neqsuryaraq": "9.3",
    "Kuvyat": "9.3.1",
    "Taluyat": "9.3.2",
    "Qalut": "9.3.3",
    "Melqulegcuryaraq": "9.4",
    "Makirayaraq": "9.5",
    "Iqvaryaraq": "9.5.1",
    "Tutmarpeknani": "9.5.1.1",
    "Kayangussuq-Avuryaraq": "9.5.2",
    "Kayanguq": "9.5.2.1",
    "Pakissaagyaraq": "9.5.3",
    "Naumalriit-Inerquutngulriit": "9.5.4",
    "Calirpagyaraq": "9.6",
    "Upnerkilleq": "9.7",
    "Uksuilleq": "9.8",
    "Neqlilleq-Neqlivik": "9.9",
    "Neqkat-Kenciksarait": "9.10",
    "Quyakluki-Ciuniurluki": "9.10.1",
    "Ilakuat-Neqerrluut-Qimugtet-Neqkait": "9.10.2",
    "Upaketaaryaraq": "9.11",
    "Caanguararkanek-Avuusqevkenaki": "9.12",
    "Tekitarkaq-Umyuaqerrlainallruat": "9.13",
    "Neqkiuryaraq": "10",
    "Keniryaraq-Egayaraq": "10.1",
    "Assaliaq": "10.1.1",
    "Segyaraq-Cegyaraq": "10.2",
    "Akerciurcecuunaki": "10.2.1",
    "Piqertuutarpeknaki": "10.2.2",
    "Kinerciriyaraq": "10.3",
    "Nakaculiyaraq": "10.3.1",
    "Qemagciyaraq": "10.5",
    "Kumlivik": "10.5.1",
    "Elagyat-Elliviit-Mamterat": "10.5.2",
    "Suluniyaraq-Culuniyaraq": "10.7",
    "Akutaq": "10.9",
    "Tenguggluk": "10.9.1",
    "Qerpertaq": "10.9.2",
    "Tunuq": "10.9.3",
    "Puya": "10.9.4",
    "Ungangkengaita-Auluksarait": "10.10",
    "Aulukumanrilnguut-Assiirutarkauciat": "10.11",
    "Neqallret": "10.12",
    "Tutmarngailngurmun-Ciqicarait": "10.12.1",
    "Anangnaqsaraq-Yuilqumi": "11",
    "Qercuaneq-Qercualleq": "11.1",
    "Kitelleq": "11.2",
    "Pugtassuutet-Kitessuutet": "11.2.1",
    "Pellaalleq": "11.3",
    "Neqkaitelleq": "11.4",
    "Meqsugyaraq": "11.5",
    "Ayagayaraq": "11.6",
    "Ayakatalriani-Upcaraq": "11.6.1",
    "Taquat": "11.6.1.1",
    "Ellaliurcuutet": "11.7",
    "Pirtuk-Pirta": "11.8",
    "Uqisvik": "11.8.1",
    "Qengaruk": "11.8.1.1",
    "Murak-Kapulluku-Ellvilisqelluku": "11.8.1.2",
    "Uqrem-Aarnarqucia": "11.8.2",
    "Qanikcaam-Acian-Epsalngunarqucia": "11.8.3",
    "Utetmun-Nallunailkuciryaraq": "11.8.4",
    "Naqugun": "11.8.5",
    "Qerruyaraq": "11.9",
    "Tequm-Urumanarqucia": "11.9.1",
    "Qerruqatalriit-Puqlamun-Pivkenaki": "11.9.2",
    "Nuussiq-Cavik-Uluaq": "11.10",
    "Imarpik": "12",
    "Imarpigmi-Anangnaqsaraq": "12.1",
    "Elegcik": "12.1.1",
    "Ketgaryaraq": "12.1.2",
    "Pugtautet": "12.1.3",
    "Tuqu-Naulluun-llu-Qenan-llu": "13",
    "Tungmagciyaraq": "13.1",
    "Qunguq": "13.1.1",
    "Cangerlak": "13.2",
    "Quserpak": "13.2.1",
    "Kaignaq-Piitnaq": "13.3",
    "Enurnaq": "13.3.1",
    "Nallunailkutet": "15.2.1.1",
    "Angucaluq": "13.3.3",
    "Arnacaluq": "13.3.4",
    "Ellam-Qupsaraa": "13.3.5",
    "Ayuquciita-Puglauciat": "13.3.6",
    "Tuquiyaraq-Nepliayaraq": "13.4",
    "Aipangyaraq-Aipaqsaraq": "14",
    "Uingyaraq": "14.1",
    "Nengaugicaraq": "14.1.1",
    "Nuliangyaraq-Nulirturyaraq": "14.2",
    "Nuliarkameng-Akiliutait": "14.2.1",
    "Ukurricaraq": "14.2.2",
    "Aipangyaraq": "14.3",
    "Angayuqrita-Cucukekaitnek-Aipangyaraq": "14.3.1",
    "Umyuallguteklutek": "14.3.2",
    "Avvucaraq": "14.4",
    "Yagirayaram-Inerquutngucia": "14.5",
    "Qingaryaraq": "22.1",
    "Irniyaraq": "22.1.2",
    "Allaliuryaraq": "14.7",
    "Qulirat-Quliraat-Qanemcit": "15",
    "Akallaat-Qanemcit": "15.1",
    "Anguyallret": "15.1.1",
    "Ukaqvallaat-Qanemcit": "15.2",
    "Tunciuryaraq": "16.2.3",
    "Qulirat-Quliraat": "15.3",
    "Ircenrraat": "15.3.1",
    "Cautaita-Ciunerkiurutait": "15.3.1.1",
    "Egacuayiit": "15.3.2",
    "Carayiit": "15.4",
    "Kassat-Tekipailgata": "16.1",
    "Nepiaq-Enepiaq-Enpiaq": "16.1.1",
    "Qacarneret": "16.1.1.1",
    "Kassat-Tekiteqerraallratni": "16.2",
    "Yupiit-(Allat-llu)-Nunautait": "16.2.1",
    "Elitnaurvikarraat": "16.2.2",
    "Yugtun-Qalartesqevkenaki": "16.2.2.1",
    "Sunaq": "16.2.4",
    "Levaat-Massiinat-Elliqeryarat": "16.2.5",
    "Aklut": "16.3",
    "Elitnaurvik": "16.4",
    "Yugtun-Kassatun-llu-Elitnauryaraq": "16.4.1",
    "Kipusviit": "16.5",
    "Pissuutet-Cassuutet-Piliat-Saskut-Ayagassuutet": "17",
    "Cassuutet-Saskut-Caskut": "17.1",
    "Ayaruq": "17.1.1",
    "Cikuliurun-Tugeq": "17.1.2",
    "Negaq": "17.1.3",
    "Kapkaanaq": "17.1.5",
    "Taluyaq": "17.1.6",
    "Cangiircuun": "17.1.6.1",
    "Cuignilngurcuun": "17.1.6.3",
    "Qanikciurun": "17.1.7",
    "Saskut-Caskut": "17.3",
    "Narulkaq": "17.3.1",
    "Ayagassuutet": "17.4",
    "Qimugta": "17.4.1",
    "Qimugciryaraq": "17.4.1.1",
    "Snuukuuq": "17.4.2",
    "Qayaq": "17.4.3",
    "Egelrun": "17.4.4",
    "Angyaq": "17.4.5",
    "Tupigyaraq": "17.5",
    "Murak": "17.6",
    "Equgtaryaraq": "17.6.2",
    "Mingqaat": "17.7",
    "Teqicilleq-Teqiciraq": "17.8",
    "Naanguat-Aquiyarat-llu": "18",
    "Irniaruat-Inuguat": "18.1",
    "Airraq": "18.2",
    "Yaaruin": "18.3",
    "Naumalriit-Enenguat": "18.4",
    "Ukverput": "19",
    "Cingumalleq": "19.2",
    "Aarcirtuutet": "19.3",
    "Qavam-Qessam-llu-Pikaitnarquciak": "19.3.1",
    "Akusrarun": "19.3.2",
    "Nunalgutkenrilkemeggnun-Nallunailuciit": "19.3.2.1",
    "Aipangvailegmeng-Akusrarcaraq": "19.3.3",
    "Qanrucunailnguut": "19.3.4",
    "Unguvamayuitut": "19.3.4.1",
    "Cacetulriim-Arenqiallugtellerkaa": "19.3.5",
    "Cigyak": "19.3.6",
    "Caniqerrilin-Caniqerrilitaq": "19.3.7",
    "Anelria-Taquilluku": "19.3.8",
    "Nakriarucaraq": "19.3.9",
    "Angutet-Iqairarkait-Allakarluki": "19.3.9.1",
    "Piciryaraput": "19.4",
    "Merricaraq": "19.4.1",
    "Qanruyutet-Qaneryarat": "19.5",
    "Piciuyaraq": "19.5.1",
    "Qigcikiyaraq": "19.5.2",
    "Qantamitun-Pitarrluku": "19.5.2.1",
    "Umyuartuyaraq": "19.5.3",
    "Maligtaqustiin-Yuunertutucia": "19.5.4",
    "Keggukarangelria": "19.5.4.1",
    "Ciunerkaqegciarkauciat": "19.5.4.2",
    "Cakviussiyaagngaitut": "19.5.4.3",
    "Elluarrluni-Yuuciquq": "19.5.4.4",
    "Qaskuit-Ayuquciit-llu": "19.5.5",
    "Cuqyucirluteng-Qanruciaqluteng": "19.5.7",
    "Qalarutesteteng-Murilkelluki": "19.5.8",
    "Alerquutet-Ayuqucirtuutet": "19.6",
    "Maligtaquyaraq-Niisngayaraq": "19.6.1",
    "Neryaraq": "19.6.2",
    "Neqem-Neqkam-Uqlautenritlkerkaa": "19.6.3",
    "Qessaicaraq": "19.6.4",
    "Enem-Ilua-Carrirturyaraa": "19.6.5",
    "Uqlam-Yugmun-Nepucuilucia": "19.6.5.1",
    "Nunat-Meret-llu-Elisngaluki": "19.6.6",
    "Tangvagluteng-Elicungcaryaraq": "19.6.7",
    "Cauryaraq": "19.6.8",
    "Calrunani-Unguvamaniartuq": "19.6.8.1",
    "Anelriani-Ellam-Qukaa-Tangerrluku": "19.6.9",
    "Qanminek-Mulngaksaraq": "19.6.10",
    "Eneteng-Uitanqegcinarqevkarluki": "19.6.11",
    "Piqrartanrilnguut-Ikayurluki": "19.6.12",
    "Ikayuqengyaraq": "19.6.13",
    "Erneq-Aipirluku": "19.6.13.1",
    "Eyagyaraq": "19.7",
    "Agleryaraq": "19.7.1",
    "Aglenraraq": "19.7.1.1",
    "Essuicaraq": "19.7.2",
    "Yuuyaraq": "19.8",
    "Ellangyaraq": "19.8.1",
    "Kencikiyaraq": "19.9",
    "Nuna-Meq-llu-Kenciklukek": "19.9.1",
    "Kenkiyaraq": "19.10",
    "Kenkucaraq": "19.10.1",
    "Kevgiurturyaraq": "19.11",
    "Akingellerkani-Umyuaqevkenaku": "19.11.1",
    "Menuurcuitut": "19.11.2",
    "Naklekiyaraq": "19.12",
    "Inerquutet": "19.13",
    "Teglegyaraq": "19.13.1",
    "Assiilkiurluteng-Neresqevkenaki": "19.13.2",
    "Qessakiluteng-Neresqevkenaki": "19.13.3",
    "Akinauresqevkenaki": "19.13.4",
    "Qessayaraq": "19.13.5",
    "Kaigasteńgurciiqut": "19.13.5.1",
    "Ellminek-Ataneqsaraq-Umyugiuryaraq": "19.13.6",
    "Nuyavvliisqevkenaki": "19.13.7",
    "Erurirraarluteng-Neqliuryaraq": "19.13.8",
    "Qumaqicaraq": "19.13.9",
    "Nepaunani-Yuuyaraq": "19.13.10",
    "Quyigingnaqsaraq": "19.13.11",
    "Kiuyaraq-Kiutaaryaraq": "19.13.12",
    "Tangaurluut-Nasaurluut-llu-Allakarluteng": "19.13.13",
    "Eyurucaraq": "19.13.14",
    "Iqlungarituut": "19.13.14.1",
    "Yuut-Nalluatni-Tegucaraq": "19.13.15",
    "Iqluyaraq-Iqluquyaraq-Iqlungaryaraq": "19.13.16",
    "Aatailnguut": "19.13.17",
    "Yuilqumi-Neplirasqevkenaki": "19.13.18",
    "Takaqiyaraq": "19.14",
    "Angayuqateng-Takaqluki": "19.14.1",
    "Ciunerkiullret": "19.15",
    "Ellam-Iquklitlerkaa": "19.15.1",
    "Yuut-Cimillerkaat": "19.15.2",
    "Eyat-Eyaryaraq": "19.16",
    "Anguyalriani-Yugtellriit": "19.16.2",
    "Kalukat": "20",
    "Kevgiq-Kevgiryaraq": "20.1",
    "Kassiyuq": "20.1.1",
    "Yuraq": "20.2",
    "Yurarpalriit": "20.2.1",
    "Yuarun": "20.2.2",
    "Anqaraun": "20.2.2.1",
    "Kegginaquq": "20.2.3",
    "Cauyaq": "20.2.4",
    "Yuulak": "20.2.4.1",
    "Nerukaraat": "20.2.5",
    "Nakaciuryaraq": "20.3",
    "Nakacuut": "20.3.1",
    "Avgarutaaryaraq": "20.4",
    "Ingulagyagaq": "20.5",
    "Uqiquryaraq": "20.6",
    "Pitqerraallret": "20.7",
    "Itrukaar": "20.8",
    "Slaaviq": "20.9.1",
    "Alussistuaq": "20.9.2",
    "Quyayaraq": "20.9.3",
    "Ilaliuryaraq": "21.6",
    "Ciuniuryaraq": "21.1",
    "Qasgiq-Qaygiq": "21.2",
    "Maqi": "21.2.1",
    "Nepiaq": "21.3",
    "Ilakucaraq-Ilakellriit-Ilakelriit": "21.4",
    "Tungayiit-Tungelqut": "21.4.1",
    "Qanertet-Qanrucistet": "21.5",
    "Nunat-Ancitullratnek": "21.7",
    "Calricaraq": "22",
    "Irnicualleq": "22.1.1",
    "Anglicarillerkaq-Tukercaryaraq": "22.2",
    "Yagiravkenaki": "22.2.1",
    "Qanpautevkenaki": "22.2.2",
    "Ceńirutevkenaki": "22.2.3",
    "Auluksaraq": "22.3",
    "Ellminek-Tuqutellerkaq": "22.4",
    "Taangaq": "22.5",
    "Naulluun-Qenan-Nangtequn": "22.6",
    "Pelqevkenaki-Ikayurluki": "22.6.1",
    "Kuingirrluk": "22.7",
    "Umyuam-Calritlerkaa": "22.8",
    "Qilnganeryaraq": "22.8.1",
    "Nernerrluut": "22.9",
    "Ilussaqercaraq": "22.9.1",
    "Piunrissiyaayuitellrat-Naulluussiyaayuunateng-llu": "22.10",
    "Yuungcarviit": "22.11",
    "Iinrut": "22.12",
    "Tabasco": "22.12.1.1",
    "Yugtaat": "22.12.2",
    "Tequq": "22.12.2.1",
    "Caqlaq": "22.12.2.2",
    "Uquq": "22.12.2.3",
    "Atsaruaq": "22.12.2.4",
    "Caiggluk-Qanganaruaq-Naunerrluk": "22.12.2.5",
    "Qalartellret-Atrit": "23",
    "Aacungaq-Willie-Kasayulie": "23.1",
    "Aalikaar-Alexie-Mochin": "23.2",
    "Acacungaqa-Clotilda-Stone": "23.3",
    "Ackiar-Nick-Lupie": "23.4",
    "Akagalria-\"Aanaq\"-Sophie-Nick": "23.5",
    "Alciq-Maxie-Altsik": "23.6",
    "Allranitailnguq-Allranaq-Joe-Chief-Sr.": "23.7",
    "Anesia-Hoover-(?)": "23.8",
    "Angalraq-Andrew-Paukan": "23.9",
    "Anganaran-Katherine-Smart-Bell": "23.10",
    "Angukarnaq-Anissum-Andrew-Jacob": "23.11",
    "Angutaq-Olinka-Nickolai": "23.12",
    "Angutevialuk-Albert-Beaver": "23.13",
    "Anguyaluk-Taacaq-Olinka-Pavilla": "23.14",
    "Apaqutaq-Dick-Andrew": "23.15",
    "Arnaaquq-Aqesgirpak-Mary-Worm": "23.16",
    "Ayaginaar-Nicholas-Charles-Sr.": "23.17",
    "Ayagkaq-Wassillie-B.-Evan": "23.18",
    "Ayaprun-James-Peter-Sr.": "23.19",
    "Benedict-Ketcheak": "23.20",
    "Betty-Guy": "23.21",
    "Billy-Lincoln-Sr.": "23.22",
    "Caingilnguq-Albert-Therchik": "23.23",
    "Callerkuaq-William-Lomack": "23.24",
    "Carl-Flynn": "23.25",
    "Cialuq-Paul-G.-Nicolai": "23.26",
    "Cikiraq-Jane-Green": "23.27",
    "Cimiciaq-Natalisia-Smith": "23.28",
    "Cingliaq-Paul-Gregory": "23.29",
    "Cungauyar-Wassllie-Andrew": "23.30",
    "Cuukvak-Mary-Neck": "23.31",
    "Emma-Phillip-(?)": "23.32",
    "Fred-Pavilla": "23.33",
    "Freda-Beaver": "23.34",
    "Ilanaq-Theresa-Moses": "23.35",
    "Imguuq-Martha-H.-Jackson": "23.36",
    "Ingallak-George-Keene": "23.37",
    "James-Guy": "23.38",
    "Joseph-Evans-(?)": "23.39",
    "Kamluq-Kirt-Bell": "23.40",
    "Kangrilnguq-Paul-John": "23.41",
    "Kayirayuaq-Eddie-Bell-Sr.": "23.42",
    "Kelucaq-Joe-Andrew": "23.43",
    "Ketacik-(?)-Ketaq-Fannie-Nicori": "23.44",
    "Kukuyar-Henry-Frank": "23.45",
    "Kumangulria-Matthew-Bean": "23.46",
    "Kumkaq-Noel-Polty": "23.47",
    "Kumluilnguq-Matthew-Frye": "23.48",
    "Liguaq-Annabelle-Olik": "23.49",
    "Lucy-Frank": "23.50",
    "Maqarualek-Edward-Wise": "23.51",
    "Maqista-Acurunaq-Joshua-Phillip": "23.53",
    "Miisaq-Milton-Lewis": "23.54",
    "Mikngayaq-Mikaq-Eliza-Chase": "23.55",
    "Mumessaq-Peter-John": "23.56",
    "Nanugaq-Fred-Evan": "23.57",
    "Narullgiar-Elena-Nick": "23.58",
    "Nauciralrea-Joseph-Friday": "23.59",
    "Nauyaq-Anna-Andrew": "23.60",
    "Nengqerralria-Nengeqaq-Elena-Charles": "23.61",
    "Neviar-Qakvaralria-Lucy-Nicholai": "23.62",
    "Nevicuk-\"Narraq\"-Helen-Nelson": "23.63",
    "Nirraralria-Ulric-Nayamin": "23.64",
    "Nurataaq-Nelson-Angapak": "23.65",
    "Oscar-Anvil-(?)": "23.66",
    "Panigkaq-Freda-Beaver": "23.67",
    "Paniguaq-Peter-Jacob": "23.68",
    "Paningayaq-Guy-Mann": "23.69",
    "Peter-Isaac": "23.70",
    "Phillip-Moses": "23.71",
    "Putyuk-Puyuk-Della-Keats": "23.72",
    "Qavarliaq-Johnny-K.-Guy": "23.73",
    "Qengauq-Oscar-F.-Alexie": "23.74",
    "Quriciq-Nickolai-K.-Nicolai": "23.75",
    "Tamarqalria-Tamaq-Elizabeth-Andrew": "23.76",
    "Taulan-Paul-Agimuk": "23.77",
    "Tavkaralria-Jonathan-Johnson": "23.78",
    "Tupagalria-(?)-Jacob-Little-(?)": "23.79",
    "Tuunralek-Jimmy-Chimegalrea": "23.80",
    "Uliggaq-Martha-Oscar-Sara": "23.81",
    "Urutaq-Lucy-Beaver": "23.82",
    "Wasky-Walters": "23.83",
    "Wassillie-J.-Andrew": "23.84",
    "Winifred-Beans": "23.85",
    "Yuk-01-Unidentified": "23.86",
    "Yuartet": "24.1",
    "Yupik-Nation": "24.2",
    "Neqliurtet-Yaquleyagiurtet-Kayanguyagiurtet": "24.3"
};