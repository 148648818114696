export const videoNum2cpb = {
    "1": "cpb-aacip-127-009w0z0q.h264",
    "2": "cpb-aacip-127-00ns1t6z.h264",
    "3": "cpb-aacip-127-010p2r15.h264",
    "4": "cpb-aacip-127-032282mq.h264",
    "5": "cpb-aacip-127-03cz8zdq.h264",
    "6": "cpb-aacip-127-0644j324.h264",
    "7": "cpb-aacip-127-0644j37r.h264",
    "8": "cpb-aacip-127-06g1jzz6.h264",
    "9": "cpb-aacip-127-06g1k008.h264",
    "10": "cpb-aacip-127-085hqfqk.h264",
    "11": "cpb-aacip-127-09w0vx3c.h264",
    "12": "cpb-aacip-127-10jsxpvr.h264",
    "13": "cpb-aacip-127-10jsxpwg.h264",
    "14": "cpb-aacip-127-10jsxpx6.h264",
    "15": "cpb-aacip-127-149p8hcz.h264",
    "16": "cpb-aacip-127-14nk9d19.h264",
    "17": "cpb-aacip-127-15p8d31m.h264",
    "18": "cpb-aacip-127-16pzgr3f.h264",
    "19": "cpb-aacip-127-18rbp380.h264",
    "20": "cpb-aacip-127-20fttjr7.h264",
    "21": "cpb-aacip-127-225b014v.h264",
    "22": "cpb-aacip-127-23612qck.h264",
    "23": "cpb-aacip-127-257d81kk.h264",
    "24": "cpb-aacip-127-257d81m9.h264",
    "25": "cpb-aacip-127-25k98x78.h264",
    "26": "cpb-aacip-127-25x69tk3.h264",
    "27": "cpb-aacip-127-26xwdh7k.h264",
    "28": "cpb-aacip-127-27zkh5wz.h264",
    "29": "cpb-aacip-127-28ncjzpp.h264",
    "30": "cpb-aacip-127-2908kvc9.h264",
    "31": "cpb-aacip-127-322bvwfr.h264",
    "32": "cpb-aacip-127-33rv1b2m.h264",
    "33": "cpb-aacip-127-34fn33pp.h264",
    "34": "cpb-aacip-127-34sj40gx.h264",
    "35": "cpb-aacip-127-35gb5sdj.h264",
    "36": "cpb-aacip-127-35t76q2k.h264",
    "37": "cpb-aacip-127-37hqc4tk.h264",
    "38": "cpb-aacip-127-37vmd1rq.h264",
    "39": "cpb-aacip-127-386hdxkk.h264",
    "40": "cpb-aacip-127-386hdz1p.h264",
    "41": "cpb-aacip-127-38w9gpk2.h264",
    "42": "cpb-aacip-127-41mgqv1k.h264",
    "43": "cpb-aacip-127-42n5thvk.h264",
    "44": "cpb-aacip-127-440rz436.h264",
    "45": "cpb-aacip-127-46254fdx.h264",
    "46": "cpb-aacip-127-47rn8x0g.h264",
    "47": "cpb-aacip-127-48ffbpfx.h264",
    "48": "cpb-aacip-127-52j6qcr2.h264",
    "49": "cpb-aacip-127-53wstz0s.h264",
    "50": "cpb-aacip-127-54kkwqvx.h264",
    "51": "cpb-aacip-127-54kkwrd6.h264",
    "52": "cpb-aacip-127-558czhn9.h264",
    "53": "cpb-aacip-127-57np5s39.h264",
    "54": "cpb-aacip-127-59c5b6f6.h264",
    "55": "cpb-aacip-127-59c5b6gx.h264",
    "56": "cpb-aacip-127-59q2c3zn.h264",
    "57": "cpb-aacip-127-60qrfsqn.h264",
    "58": "cpb-aacip-127-612ngp56.h264",
    "59": "cpb-aacip-127-623bkbxr.h264",
    "60": "cpb-aacip-127-6341p189.h264",
    "61": "cpb-aacip-127-63fxpxg0.h264",
    "62": "cpb-aacip-127-65h9w88k.h264",
    "63": "cpb-aacip-127-65v6x4wh.h264",
    "64": "cpb-aacip-127-66vx0v02.h264",
    "65": "cpb-aacip-127-67wm3hhj.h264",
    "66": "cpb-aacip-127-68kd59ft.h264",
    "67": "cpb-aacip-127-69m37zr9.h264",
    "68": "cpb-aacip-127-719kdf8m.h264",
    "69": "cpb-aacip-127-719kdfjd.h264",
    "70": "cpb-aacip-127-74cnpfqb.h264",
    "71": "cpb-aacip-127-74cnpfr2.h264",
    "72": "cpb-aacip-127-74qjqbsc.h264",
    "73": "cpb-aacip-127-752fr7q2.h264",
    "74": "cpb-aacip-127-7634tx89.h264",
    "75": "cpb-aacip-127-76f1vspw.h264",
    "76": "cpb-aacip-127-773txm5v.h264",
    "77": "cpb-aacip-127-784j17vw.h264",
    "78": "cpb-aacip-127-79h44thw.h264",
    "79": "cpb-aacip-127-805x6mnx.h264",
    "80": "cpb-aacip-127-816m99hb.h264",
    "81": "cpb-aacip-127-81jhb5w6.h264",
    "82": "cpb-aacip-127-84mkm6tr.h264",
    "83": "cpb-aacip-127-85n8pw5n.h264",
    "84": "cpb-aacip-127-86nzsk0r.h264",
    "85": "cpb-aacip-127-87brvbt5.h264",
    "86": "cpb-aacip-127-87pnw7r9.h264",
    "87": "cpb-aacip-127-881jx4d6.h264",
    "88": "cpb-aacip-127-89280sv6.h264",
    "89": "cpb-aacip-127-89r22kjv.h264",
    "90": "cpb-aacip-127-90rr586b.h264",
    "91": "cpb-aacip-127-91sf7xm0.h264",
    "92": "cpb-aacip-127-945qg6jq.h264",
    "93": "cpb-aacip-127-956djvkd.h264",
    "94": "cpb-aacip-127-96wwq9xv.h264",
    "95": "cpb-aacip-127-988gtvzp.h264",
    "96": "cpb-aacip-127-98mcvs0p.h264"
};